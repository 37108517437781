import React, { useState, useEffect } from 'react';
import { Location } from '@reach/router'

import Publish from '../../components/publish'
import Layout from "../../components/layout"
// import queryString from 'query-string'


const Index = () => {


    return (
        <Layout>
            <Publish/>
        </Layout>
    )
}

export default Index
