import { ConsoleLogger } from "@aws-amplify/core";
import _ from "lodash";
import shorthash from "shorthash";

export function publishContent(state, trust_info, pathway_id) {
  //const pathway_id = "b37f6c1d-3bcf-4a96-9453-23aa9adaa4fe"
  //const pathway_id = "6c9a4047-27f3-42e4-b823-76e6a426e713"

  const user = state.user;
  var trust = trust_info;
  const tiles = state.tiles;
  const pages = state.pages;
  var sections = state.sections;
  const junctionPages = state.junctionPages;
  const pathways = state.pathways;
  const pathway = pathway_id;
  const images = state.images;
  const wellnessTriggers = state.wellnessTriggers;

  const wellnessForms = state.wellnessForm;
  const wellnessQuestions = state.wellnessQuestion;

  var link_level_depth = 0;
  var errors = [];
  var tile_array = [];
  var pages_object = {};
  var new_wellness_array = [];

  try {
    ////////////////////////////////////
    // TILES and PAGES
    ////////////////////////////////////
    try {
      tiles.map((tile) => {
        // If the tile destination is null - need to return an error
        if (tile.destination === null) {
          if (tile.alternative_link !== "Wellness") {
            errors.push({
              type: "No destination assigned to tile",
              data: tile,
            });
            return;
          }
        }
        // if the tile junction page is selected - we need to change the destination and name of the tile
        if (tile.junction_page_selected === true && pathway !== "None") {
          const find_junction_page = _.find(junctionPages, {
            id: tile.JunctionPage,
          });
          if (find_junction_page === undefined) {
            return;
          }
          // parse the data
          const junction_data = JSON.parse(find_junction_page.junction_data);
          // find the selected junction
          const junction_details = _.find(junction_data, { id: pathway.id });
          var new_tile_object = { ...tile }; // create a new tile object
          if (junction_details !== undefined) {
            new_tile_object.title = junction_details.title || "No title"; // set new title
            new_tile_object.destination = junction_details.destination; // set new destination
          }
          // Push tile
          tile_array.push(new_tile_object);
          process_page(new_tile_object.destination);
          return;
        }
        tile_array.push(tile);
        process_page(tile.destination);
        return;
      });
    } catch (error) {
      errors.push({
        type: "Error processing tiles and pages",
        data: JSON.stringify(error),
      });
    }

    // // This function will take the page_id, and add the page to the page array along with the sections.
    function process_page(destination) {
      try {
        function updateSectionsIfJunction(sections_to_check) {
          var new_sections_array = [...sections_to_check];
          sections_to_check.map((section, index) => {
            if (
              section.type === "link" &&
              section.page_link_type === "Junction"
            ) {
              console.log("WE HAVE A JUNCTION");
              const find_junction_page = _.find(junctionPages, {
                id: section.alternative_link,
              });
              const junction_data = JSON.parse(
                find_junction_page.junction_data
              );
              // find the selected junction
              const junction_details = _.find(junction_data, {
                id: pathway.id,
              });
              var new_section_object = { ...section }; // create a new tile object
              if (junction_details !== undefined) {
                new_section_object.title = junction_details.title || "No title"; // set new title
                new_section_object.destination = junction_details.destination; // set new destination
              }
              new_sections_array.splice(index, 1, new_section_object);
            }
          });
          return new_sections_array;
        }
        const filter_page = _.find(pages, { page_id: destination });
        if (filter_page === undefined) {
          return;
        }

        const filter_sections = _.filter(sections, { section_id: destination });
        var ordered_sections = _.sortBy(filter_sections, ["index_number"]);
        var checked_sections = updateSectionsIfJunction(ordered_sections);
        pages_object[destination] = {
          title: filter_page.title,
          page_id: filter_page.page_id,
          sections: checked_sections,
        };

        // Filter through the sections and check for links
        checked_sections.map((section, index) => {
          if (section.type === "link") {
            link_level_depth = link_level_depth + 1;
            // console.log(link_level_depth);
            if (link_level_depth > 10) {
              console.log("pushing error");
              console.log(pathway_id);
              errors.push({ type: "Link depth exceeds 10", data: section });
              return;
            }
            if (
              section.page_link_type === "Wellness" ||
              section.page_link_type === "Timeline"
            ) {
              return;
            }
            process_page(section.destination);
            return;
          }
          if (section.type === "cta" && section.data === "internal") {
            link_level_depth = link_level_depth + 1;
            // console.log(link_level_depth);
            if (link_level_depth > 10) {
              errors.push({ type: "Link depth exceeds 10", data: section });
              return;
            }
            if (
              section.page_link_type === "Wellness" ||
              section.page_link_type === "Timeline"
            ) {
              return;
            }
            process_page(section.destination);
            return;
          }
          if (section.type === "info" && section.data === "internal") {
            // console.log("ADDING INFO LINK");
            link_level_depth = link_level_depth + 1;
            // console.log(link_level_depth);
            if (link_level_depth > 10) {
              errors.push({ type: "Link depth exceeds 10", data: section });
              return;
            }
            if (
              section.page_link_type === "Wellness" ||
              section.page_link_type === "Timeline"
            ) {
              return;
            }
            process_page(section.destination);
            return;
          }
          if (section.type === "accordion" && section.data === "internal") {
            // console.log("ADDING INFO LINK");
            link_level_depth = link_level_depth + 1;
            // console.log(link_level_depth);
            if (link_level_depth > 10) {
              errors.push({ type: "Link depth exceeds 10", data: section });
              return;
            }
            if (
              section.page_link_type === "Wellness" ||
              section.page_link_type === "Timeline"
            ) {
              return;
            }
            process_page(section.destination);
            return;
          }
        });
        link_level_depth = 0;
        return;
      } catch (error) {
        errors.push({
          type: "Error processing through publish page",
          data: JSON.stringify(error),
        });
      }
    }

    ////////////////////////////////////
    // Wellness data
    ////////////////////////////////////
    const parsed_wellness_forms_array = JSON.parse(
      pathway.wellness_forms_array
    );

    try {
      wellnessForms.map((wellnessForm, index) => {
        // Is the form included in this pathway
        const is_wellness_form_included_in_pathway = _.includes(
          parsed_wellness_forms_array,
          wellnessForm.id
        );

        //////// ADD IN INDEX SORTING FOR WELLNESS WHEN READY /////

        // If true then form new object and add queestions

        if (is_wellness_form_included_in_pathway === true) {
          var new_object = {};
          var section_questions = _.filter(wellnessQuestions, {
            form_id: wellnessForm.id,
          });
          if (section_questions !== undefined) {
            // console.log("section_questions");
            // console.log(section_questions);
            section_questions.map((question, index) => {
              var parsed_answer_array = question.answer_array;
              // console.log(typeof question.answer_array);
              if (typeof question.answer_array === "string") {
                parsed_answer_array = JSON.parse(question.answer_array);
              }
              section_questions[index].answer_array = parsed_answer_array;
            });
            new_object = { ...wellnessForm };
            new_object.form_array = section_questions;
            if (section_questions.length === 0) {
              errors.push({
                type: "Wellness form does not have any questions assigned",
                data: new_object,
              });
            }
            //new_wellness_array[index].form_array = section_questions;
            new_wellness_array.push(new_object);
          }
        }
      });
    } catch (error) {
      errors.push({
        type: "Error with wellness data",
        data: JSON.stringify(error),
      });
    }

    ////////////////////////////////////
    // PATHWAYS //
    ////////////////////////////////////

    var trust_image_url = trust.image_url;
    // If pathway has image uploaded, change it
    if (pathway.image_url !== null) {
      trust_image_url = pathway.image_url;
    }
    const timeline_data = JSON.parse(pathway.timeline_data);
    try {
      timeline_data.map((data, index) => {
        // console.log(data);
        data.card_array.map((card) => {
          process_page(card.destination);
        });
      });
    } catch (error) {
      errors.push({
        type: "Error with PATHWAYS data",
        data: JSON.stringify(error),
      });
    }

    ////////////////////////////////////
    /// IMAGES ///
    ////////////////////////////////////

    // Image processing
    const images_to_cache = {};

    //first scan pages
    console.log("scanning images");
    const filter_section_images = _.filter(sections, {
      image_type: "external",
    });
    console.log(filter_section_images);
    console.log(images);
    var image_error_list = [];
    filter_section_images.map((image) => {
      try {
        if (!!image?.image_url) {
          const found_image = _.filter(images, { hash: image.image_hash });

          if (found_image.length === 0) {
            console.log("found_image");
            console.log(image);
            console.log(found_image);
          }
          // images_to_cache[image.image_hash] = {
          //   image_url: found_image.image_url,
          //   cached_image: null,
          //   version_id: found_image.version_id,
          // };
        }
      } catch (error) {
        console.log("Error with IMAGES data");
        console.log("pathway");
        console.log(pathway);
        console.log("image");
        console.log(image);
        image_error_list.push(image);
        const found_image = _.find(images, { hash: image.image_hash });
        console.log(image.image_hash);
        console.log(images);
        console.log(found_image);
        console.log(error);
        console.log(image);
        errors.push({
          type: "Error with IMAGES data",
          data: JSON.stringify(error),
        });
      }
    });
    console.log("image_error_list");
    console.log(image_error_list.length);
    console.log(image_error_list);
    // Cache timeline images //
    try {
      timeline_data.map((data, index) => {
        data.card_array.map((card) => {
          if (card.image_type === "external") {
            const found_image = _.find(images, { hash: card.hash });
            images_to_cache[card.hash] = {
              image_url: found_image.image_url,
              cached_image: null,
              version_id: found_image.version_id,
            };
          }
        });
      });
    } catch (error) {
      errors.push({
        type: "Error with TIMELINE IMAGE data",
        data: JSON.stringify(error),
      });
    }

    ////////////////////////////////////
    // TIGGERS //
    ////////////////////////////////////

    try {
      wellnessTriggers.map((trigger, index) => {
        console.log("trigger.destination");
        console.log(trigger.destination);
        process_page(trigger.destination);
      });
    } catch (error) {
      errors.push({
        type: "Error with Triggers data",
        data: JSON.stringify(error),
      });
    }

    // NEED TO CACHE TRUST AND SUNRISE LOGO IMAGES ///

    ////////////////////////////////////
    /// RETURN DATA ///
    ////////////////////////////////////
    const ordered_tiles = _.sortBy(tile_array, ["index_number"]);
    const data_to_return = {
      trust: { ...trust, image_url: trust_image_url },
      tiles: ordered_tiles,
      pages: pages_object,
      section_images: images_to_cache,
      wellness: new_wellness_array,
      timeline: JSON.parse(pathway.timeline_data),
      pathway_info: { hero_section: JSON.parse(pathway.hero_section) },
      triggers: wellnessTriggers,
      errors: errors,
    };
    // return {tiles: tiles, pages: pages, section_images: images}
    console.log("data_to_return");
    console.log(data_to_return);

    return { data_to_return };
  } catch (error) {
    console.log("error");
    console.log(error);
    const data_to_return = {
      errors: { type: "Undefined Error", data: JSON.stringify(error) },
    };
    return { data_to_return };
  }
}
