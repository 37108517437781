import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import GitInfo from "react-git-info/macro";
import {
  fetchJunctionPages,
  fetchPages,
  fetchPathways,
  fetchSections,
  fetchTiles,
  fetchWellnessForms,
  fetchWellnessQuestions,
  fetchAllPathways,
  fetchImages,
  fetchTriggers,
} from "../../actions";
import s3config from "../../s3config";
import _ from "lodash";
import {
  Segment,
  Button,
  Header,
  Divider,
  Loader,
  Dimmer,
} from "semantic-ui-react";

import { update_Trust, update_Pathway } from "../../actions";
import { publishContent } from "./publishFunction";
import { s3UploadJsonPathway } from "../../libs/awsLib";

const Pubish = () => {
  const branch_name = GitInfo().branch;
  const dispatch = useDispatch();

  const [loading, update_loading] = useState(true);
  const pathways = useSelector((state) => state.pathways);
  const user = useSelector((state) => state.user);
  const trusts = useSelector((state) => state.trusts);

  const state = useSelector((state) => state);
  const [current_index_updated, update_current_index_updated] = useState(-1);
  const [publish_pathways_complete, update_publish_pathways_complete] =
    useState(false);

  const [json, update_json] = useState([]);
  const [thrown_errors, update_thrown_errors] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    await dispatch(fetchPathways(user["custom:trust"]));
    await dispatch(fetchTiles(user["custom:trust"]));
    await dispatch(fetchPages(user["custom:trust"]));
    await dispatch(fetchSections(user["custom:trust"]));
    await dispatch(fetchJunctionPages(user["custom:trust"]));
    await dispatch(fetchWellnessForms(user["custom:trust"]));
    await dispatch(fetchWellnessQuestions(user["custom:trust"]));
    await dispatch(fetchImages());
    await dispatch(fetchTriggers());
    update_loading(false);
  }

  async function publish() {
    // Copy a local version of pathways
    var local_version_pathways = [...pathways];

    // Find current trust and update version number
    var trust_info = _.find(trusts, { trust_id: user["custom:trust"] });
    trust_info.version = trust_info.version + 1;
    dispatch(
      update_Trust({ id: trust_info.id, version: Number(trust_info.version) })
    );

    console.log("prior to map");

    const delay = (ms) => new Promise((res) => setTimeout(res, ms));

    try {
      var new_errors_array = [];
      var full_list = [];

      await local_version_pathways.map(async (pathway, index) => {
        // if the pathway is a redirect, then dont need to publish this.
        if (pathway.redirect !== null) {
          console.log("redirected pathway so dont publish");
          update_current_index_updated(index);
          await delay(2000);
          return;
        }

        // name for the new pathway file to include vesion
        var name =
          trust_info.trust_id +
          pathway.id +
          "V" +
          Date.now().toString() +
          trust_info.version;

        //move to publish content for this pathway.
        const response = publishContent(state, trust_info, pathway);

        console.log(trust_info.name);
        console.log(response.data_to_return);

        if (response.data_to_return.errors.length !== 0) {
          new_errors_array.push({
            id: index,
            data: response.data_to_return.errors,
          });
        }
        console.log(name);
        full_list.push(response);
        //return;
        const s3_response = await s3UploadJsonPathway(response, name);
        console.log(s3_response);

        var url = s3config.S3.URL + s3config.S3.EXT + s3_response;

        console.log("URL");
        console.log(url);
        console.log("Errors");
        console.log(new_errors_array);
        await dispatch(
          update_Pathway({
            id: pathway.id,
            url: url,
            version: Number(trust_info.version),
          })
        );
        local_version_pathways[index].url = url;

        update_current_index_updated(index);

        await timeout(40000); //for 1 sec delay
      });
      update_thrown_errors(new_errors_array);
    } catch (error) {
      console.log("ERROR");
      console.log(error);
    }

    console.log("after to map");

    update_publish_pathways_complete(true);
  }

  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }
  function returnErrors(errors_array) {
    return;
    return errors_array.map((error) => {
      return <div>{error.type}</div>;
    });
  }

  function renderPathways() {
    const list = pathways.map((pathway, index) => {
      var colour = "blue";
      var background_colour = "#efefef";
      const errors = _.find(thrown_errors, { id: index });
      console.log(errors);
      if (current_index_updated >= index) {
        colour = "green";
        background_colour = "#acd5aa";
      }
      if (errors) {
        colour = "red";
        background_colour = "red";
      }
      if (pathway.redirect !== null) {
        return;
      }
      return (
        <div style={{ marginTop: 15 }}>
          <Segment
            style={{
              textAlign: "center",
              maxWidth: 600,
              background: background_colour,
            }}
            color={colour}
          >
            <Header as="h3">{pathway.title}</Header>
            <p>Pathway ID: {pathway.id}</p>
            {/* <div onClick={() => console.log(pathway)}>data</div> */}
            {pathway.redirect === null ? (
              <></>
            ) : (
              <>
                redirected to {_.find(pathways, { id: pathway.redirect }).title}
              </>
            )}
          </Segment>
          {errors ? (
            <>
              <Segment
                inverted
                color="red"
                secondary
                style={{ textAlign: "center", maxWidth: 600 }}
              >
                {returnErrors(errors.data)}
                {JSON.stringify(errors.errors)}
              </Segment>
            </>
          ) : (
            <div>{JSON.stringify(errors)}</div>
          )}
        </div>
      );
    });
    return <div>{list}</div>;
  }
  if (loading) {
    return (
      <Dimmer active>
        <Loader inverted active size="medium" content="Loading Data..." />
      </Dimmer>
    );
  }
  return (
    <div>
      <h1>Publish</h1>
      <Divider />
      <h3>Publish Pathways</h3>
      <p>Select publish, and a new version of each pathway will be released</p>

      <Segment
        style={{ textAlign: "center", background: "#efefef", maxWidth: 600 }}
        color="blue"
      >
        <h4>Publish an updated version of the trust pathways</h4>
        <div style={{ marginBottom: 10 }}>
          Note: Redirected Pathways are not displayed here
        </div>
        {publish_pathways_complete === true ? (
          <>
            <Button disabled style={{ width: 500 }} positive>
              Publish Complete
            </Button>
          </>
        ) : (
          <>
            <Button style={{ width: 500 }} primary onClick={() => publish()}>
              Publish
            </Button>
          </>
        )}
      </Segment>
      {renderPathways()}
    </div>
  );
};

export default Pubish;
